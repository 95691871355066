import React from 'react';
import MessageBubble from './MessageBubble';
import LoadingMessage from './LoadingMessage';

const ChatDisplay = ({ messages, isLoading, messagesEndRef }) => {
  return (
    <div className="flex-grow overflow-auto p-4 pb-24">
      <div className="max-w-2xl mx-auto space-y-4">
        {messages.map((message, index) => (
          <MessageBubble key={index} message={message} />
        ))}
        {isLoading && (<LoadingMessage />)}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatDisplay;