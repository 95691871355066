import React from 'react';

const ChatHeader = ({ listingName }) => {
  return (
    <header className="bg-white shadow-sm p-4 text-center">
      {listingName ? (
        <div>
          <h1 className="text-xl font-bold text-gray-800">{listingName}</h1>
        <div>
          <p className="text-med">Welcomatic AI</p>
        </div>
      </div>
      ) : (
        <div>
          <h1 className="text-xl font-bold text-gray-800">Welcomatic AI</h1>
        </div>
      )}
    </header>
  );
};

export default ChatHeader;