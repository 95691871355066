import React from 'react';

const MessageBubble = ({ message }) => {
  const isUser = message.sender === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'}`}>
      <div
        className={`max-w-[80%] p-3 rounded-lg ${
          isUser
            ? 'human-message shadow-sm'
            : 'bot-message shadow-sm'
        }`}
      >
        {message.text}
      </div>
    </div>
  );
};

export default MessageBubble;