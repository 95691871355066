import React from 'react';

const LoadingMessage = ({ input, setInput, handleSend, isLoading }) => {
  return (
    <div className="flex justify-start">
    <div className="bg-white bot-message p-3 rounded-lg shadow-sm flex items-center space-x-2">
      <div className="w-2 h-2 bot-message rounded-full animate-pulse"></div>
      <div className="w-2 h-2 bot-message rounded-full animate-pulse delay-75"></div>
      <div className="w-2 h-2 bot-message rounded-full animate-pulse delay-150"></div>
    </div>
  </div>
  );
};

export default LoadingMessage;