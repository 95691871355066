import React from 'react';

const ChatInput = ({ input, setInput, handleSend, isLoading }) => {
  return (
    <div className="fixed bottom-4 left-4 right-4">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg p-2 flex items-center">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Ask a question"
              className="flex-grow p-2 bg-transparent focus:outline-none text-gray-900 placeholder-gray-300"
            />
            <button
              onClick={handleSend}
              className="p-2 rounded-full send-message text-white transition duration-300 focus:outline-none focus:ring-2"
              disabled={isLoading}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
              </svg>
            </button>
          </div>
        </div>
      </div>
  );
};

export default ChatInput;