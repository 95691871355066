import React from 'react';

function Home() {
  return (
    <div className="flex flex-col h-screen bg-gray-50 font-sans">
      <h1 className="text-xl font-bold text-gray-800">
        Welcomatic
      </h1>
    </div>
  );
}

export default Home;